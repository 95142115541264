import { compact } from 'lodash'
import { addSelectOptions } from 'services/select'

class LineItemParties {
  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.$partySelect = this.el.find('select.party-select')

    this.bindEvents()
  }

  bindEvents() {
    if (this.doc.isReadonly()) return
    if (this.doc.isSelectPartyMode()) this.$partySelect.change(this.handleChangeParty)
  }

  fetchParties() {
    let parties = this.lineItem.product.parties || []
    const modificationId = this.lineItem.modifications.selectedId()
    const datevalid = this.lineItem.datevalids.selectedId()
    const serial = this.lineItem.isSerial() ? this.lineItem.serials.fetchSerials()[0] : null

    if (modificationId) parties = parties.filter(party => party.modification_id === modificationId)
    if (datevalid) parties = parties.filter(party => party.datevalid === datevalid)
    if (serial) parties = parties.filter(party => party.serial_number == serial)

    return parties
  }

  setPartySelect() {
    const isParty = this.doc.companySettings.party_accounting
    const isSerial = this.lineItem.isSerial()

    const disabled = this.lineItem.isProductKit() || (isSerial && isParty)
    this.$partySelect.prop('disabled', disabled)

    if (disabled) return addSelectOptions(this.$partySelect, [], { clear: true, allowBlank: true })

    const options = this.fetchParties().map(item => {
      let partyOrPrice = item.price_balance
      const quantity = item.balance_quantity || 0
      const price = item.price_balance || 0

      if (isParty) partyOrPrice = item.party_code || I18n.t('document_items.without_party')

      const details = compact([
        isParty ? `${I18n.t('prices.price')}: ${price || '-'}` : null,
        `${I18n.t('main.remain')}: ${quantity}`
      ]).join(', ')

      return {
        label: `<strong>${partyOrPrice}</strong><span class='party-details' data-price='${price}'>(${details})</span>`,
        value: partyOrPrice
      }
    })

    const allowBlank = this.doc.data.auto_parties ? window.I18n.t('main.auto').toLowerCase() : true

    addSelectOptions(this.$partySelect, options, {
      label: 'label', value: 'value', clear: true, allowBlank
    })

    if (this.doc.data.auto_parties) {
      this.$partySelect.val('').trigger('change')
    } else if (options.length === 1) {
      this.$partySelect.val(options[0].value).trigger('change')
    } else if (options.length > 1 && !this.lineItem.isSerial() && !this.doc.applyProductsFromCatalogPending) {
      // setTimeout is neccessary, because select2 dropdown has fixed position,
      // and line items column width will be changed during product insertion,
      // than select2 dropdown will have wrong position.
      setTimeout(() => this.$partySelect.select2('open'), 300)
    }
  }

  selected() {
    const partyCode = this.$partySelect.val()
    let parties = this.fetchParties()

    if (!this.doc.companySettings.party_accounting) {
      return parties.find(p => Number(p.price_balance || 0) === Number(partyCode || 0))
    }

    // Yes, it could be many paties with same partyCode.
    parties = parties.filter(p => p.party_code === (partyCode || ''))

    if (parties.length <= 1) return parties[0]

    // Edge Case.
    // Product could have multiple parties with same party code, but with different prices.
    // This happens when inside single supply document added multiple document items with
    // same product (modification, datevalids), but with different prices.
    // That's why data-price is necessary.
    const optionHtml = this.$partySelect.select2('data')[0].text
    if (!optionHtml) return

    const price = Number($(`<div>${optionHtml}</div>`).find('.party-details').data('price') || 0)

    return parties.find(p => Number(p.price_balance || 0) === price)
  }


  // On party change we should update fields for documents product_off, overvalue, correction_balance, disassembly
  // Depending on company settings party_accounting we perform different logic.
  // Party could be found by party code or by balance price (if party_accounting disabled).
  handleChangeParty = (event) => {
    const party = this.selected()

    if (!party) return

    // Только для документов [product_off overvalue correction_balance disassembly]
    // При партионном учете:
    // - для Переоценки приходная цена и количество выбирается от партии
    // - для Списания и Корректировки остатка цена выбирается от партии
    // - для случаев, когда поле партии пустое (в старых приходах) количество берем как actual_quantity
    switch (this.doc.typedoc) {
      case 'product_off':
        this.onChangeProductOffParty(party)
        break
      case 'correction_balance':
        this.onChangeBalanceCorrectionParty(party)
        break
      case 'overvalue':
        this.onChangeOvervalueParty(party)
        break
      case 'disassembly':
        this.onChangeProductOffParty(party)
    }

    this.lineItem.calculate({event})
  }

  onChangeProductOffParty(party) {
    const price = party.price_balance
    this.el.find('.price.price-document').val(price)
    this.el.find('.price.price-accounting').val(price)
    this.el.find('.admin-price-accounting').text(price)
  }

  onChangeBalanceCorrectionParty(party) {
    if (this.doc.companySettings.party_accounting) {
      const price = party.price_balance
      this.el.find('.price.price-document').val(price)
      this.el.find('.price.price-accounting').val(price)
      this.el.find('.admin-price-accounting').text(price)
    }

    this.el.find('.correction-balance-party-quantity').text(party.balance_quantity)
  }

  onChangeOvervalueParty(party) {
    if (this.doc.companySettings.party_accounting) {
      this.el.find('.price-supply').val(party.price_balance)
    }
    this.el.find('.quantity').val(party.balance_quantity)
  }
}

export default LineItemParties
