import { escapeRegex } from 'services/regex'
import { addSelectOptions, clearSelectOptions } from 'services/select'
import { CREATE_OPTION } from 'services/select'

class LineItemModifications {
  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.$select = this.el.find('select.modifications-select')

    this.bindEvents()
  }

  isEnabled() {
    return !!(this.lineItem.product.barcode_accounting && this.$select.length)
  }

  bindEvents() {
    if (this.doc.isReadonly()) return

    this.$select.on('select2:selecting', this.openModificationModal)
    this.$select.change(this.handleChange)
  }

  selectedId() {
    return this.$select.val()
  }

  selected() {
    return (this.lineItem.product.modifications || []).find(item => item.id === this.selectedId())
  }

  openModificationModal = (e) => {
    if (e.params.args.data.id !== CREATE_OPTION || !this.isEnabled()) return
    e.preventDefault()
    this.$select.select2('close')

    if (!this.lineItem.productId) return

    this.doc.openModificationModal(this.lineItem)
  }

  handleChange = (event) => {
    const modificationId = $(event.target).val()

    // Should be before setPartySelect, otherwise price will be empty in balance price documents
    this.lineItem.calculator.resetPrices()
    this.lineItem.setRemains()
    this.lineItem.setImage()

    if (this.doc.isSelectPartyMode()) this.lineItem.parties.setPartySelect()
    if (this.lineItem.datevalids.isEnabled()) this.lineItem.datevalids.reload()

    this.lineItem.calculate({event, recalculateCurrency: true})
  }

  reload() {
    if (!this.isEnabled()) {
      if (this.$select.length) clearSelectOptions(this.$select)
      this.$select.prop('disabled', true)
      return
    }

    this.$select.prop('disabled', false)

    addSelectOptions(this.$select, this.lineItem.product.modifications, {
      label: 'to_s',
      value: 'id',
      clear: true,
      selected: this.matchedModification(this.lineItem.product),
      allowBlank: true
    })
  }

  matchedModification(product) {
    const modifications = product.modifications

    if (!modifications.length) return null
    // When product inserted via catalog modification already selected
    if (product.modificationId) return modifications.find(item => item.id === product.modificationId)

    const regex = new RegExp(`(${escapeRegex(this.lineItem.search.query)})$`)
    const matched = modifications.find(item => !!item.barcode.match(regex))
    return matched || modifications[0]
  }
}

export default LineItemModifications
