import { uniqBy } from 'lodash'
import { addSelectOptions, clearSelectOptions } from 'services/select'

class LineItemDatevalids {
  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.$select = this.el.find('select.datevalid')
    this.$input = this.el.find('input.datevalid')

    this.bindEvents()
  }

  isEnabled() {
    return !!(this.lineItem.product.datevalid_accounting && (this.$select.length || this.$input.length))
  }

  bindEvents() {
    if (this.doc.isReadonly()) return

    this.$select.change(this.handleChange)
    this.$input.change(this.handleChange)
  }

  selectedId() {
    return this.$select.val()
  }

  selected() {
    return this.all().find(item => item.datevalid === this.selectedId())
  }

  all() {
    let datevalids = this.lineItem.product.datevalids || []
    const modificationId = this.lineItem.fetchModificationId()

    if (modificationId) datevalids = datevalids.filter(datevalid => datevalid.modification_id === modificationId)

    return uniqBy(datevalids, 'datevalid')
  }

  handleChange = (event) => {
    const datevalid = $(event.target).val()

    if (this.doc.isSelectPartyMode()) this.lineItem.parties.setPartySelect()

    this.lineItem.lazySave()
  }

  // TODO: Trigger this on document stock change
  reload() {
    if (!this.isEnabled()) {
      if (this.$select.length) clearSelectOptions(this.$select)
      this.$select.prop('disabled', true)
      this.$input.prop('disabled', true)
      return
    }

    this.$select.prop('disabled', false)
    this.$input.prop('disabled', false)

    const datevalids = this.all()
    const current = datevalids.find(item => item.datevalid === this.selectedId())

    addSelectOptions(this.$select, datevalids, {
      label: 'datevalid',
      value: 'datevalid',
      clear: true,
      selected: current,
      allowBlank: this.doc.data.auto_datevalid ? window.I18n.t('main.earliest').toLowerCase() : true
    })
  }
}

export default LineItemDatevalids
