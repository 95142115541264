import { debounce, delay } from 'lodash'
import { errorMessage } from 'services/errors'
import axios from 'axios'
import qs from 'qs'
import { formToObject } from 'services/form'
import { extractWeightFromBarcode, isWeightBarcode } from 'services/barcode'
import { camelizeKeys, decamelizeKeys } from 'services/objects'
import LineItemCalculator from './line_item/calculator'
import LineItemSearch from './line_item/search'
import LineItemParties from './line_item/parties'
import LineItemModifications from './line_item/modifications'
import LineItemDatevalids from './line_item/datevalids'
import LineItemSerials from './line_item/serials'
import LineItemCurrency from './line_item/currency'
import { fetchNumberInputValue, formattedQuantity, subtract } from 'services/numbers'
import { isLineItemAllowMaterials } from 'services/documents'

class LineItem {
  constructor(doc, el) {
    this.doc = doc
    this.el = el
    this.$id = this.el.find('.line-item-id')
    this.id = this.$id.val()
    this.product = this.el.data('product') || {}
    this.productId = this.el.find('.product-id').val()
    this.$metadata = this.el.find('.metadata')
    this.form = this.el.closest('form')
    this.lazySave = debounce(this.save, 300)
    this.editMode = false
    this.blockCalculate = false
    this.retry = null
    this.position = this.detectPosition()
    this.doc.nextLineItemPosition = this.position + 1
    this.initHelpers()
    this.setRemains()
    this.setImage()
    this.bindEvents()
  }

  detectPosition() {
    let position = this.el.find('.ord-position').val()

    if (position) return Number(position)

    return this.doc.nextLineItemPosition
  }

  initHelpers() {
    this.calculator = new LineItemCalculator(this)
    this.search = new LineItemSearch(this)
    this.parties = new LineItemParties(this)
    this.modifications = new LineItemModifications(this)
    this.datevalids = new LineItemDatevalids(this)
    this.serials = new LineItemSerials(this)
    this.currency = new LineItemCurrency(this)
  }

  bindEvents() {
    this.el.find('.print-trigger-btn').click(this.onPrint)
    this.el.find('.open-product-modal-btn').click(this.openProductModal)
    this.el.find('.edit-btn').click(this.openProductModal)
    this.el.find('.scan-btn').click(this.doc.toggleScanMode)
    this.el.find('.link-btn').click(this.openProductPage)
    this.el.find('.table-cell').dblclick(this.openLineItemMovements)
    this.el.find('.open-products-catalog-modal-btn').click(this.openProductsCatalog)
    this.el.find('.calculation-btn').click(this.openProductCalculation)

    if (this.doc.isReadonly()) return

    this.el.find('input, select').keydown(this.navigate)
    this.el.find('input, select').focus(this.handleInputFocus)
    this.el.find('input, select').blur(this.handleInputUnfocus)
    this.el.find('.status-icon.error-icon').click(this.retryRequest)
    this.el.find('.delete-btn').click(this.destroy)
    this.el.find('.clone-btn').click(this.clone)
  }

  calculate = (...args) => {
    return this.calculator.calculate(...args)
  }

  retryRequest = (e) => {
    this.retry === 'destroy' ? this.destroy(e) : this.lazySave()
  }

  resourceUrl() {
    return [gon.locale_path, 'platform', 'document_items', this.id].join('/')
  }

  save = async () => {
    if (this.doc.isReadonly()) return
    if (!this.productId) return false

    if (this.savePending) {
      this.editedDuringSave = true
      return
    }
    this.savePending = true

    this.retry = 'save'
    this.toggleErrorIcon(false)
    this.toggleProgressIcon(true)
    const method = this.id ? 'put' : 'post'
    const params = this.saveParams()

    try {
      const response = await axios[method](this.resourceUrl(), params)
      const data = response.data
      this.toggleProgressIcon(false)
      if (data.error) return this.handleRequestError(data.error)
      this.id = data.id
      this.$id.val(data.id)
      this.retry = null

      if (this.editedDuringSave) this.lazySave()
    } catch (e) {
      this.handleRequestError(errorMessage(e))
      console.error(e)
    } finally {
      this.savePending = false
      this.editedDuringSave = false
    }
  }

  saveParams() {
    return {
      ...formToObject(this.form, 'document_item'),
      document: formToObject(this.doc.form, 'document').document,
    }
  }

  // TODO: Clone also prices
  clone = (e) => {
    e.preventDefault()
    const modification = this.modifications.selected()
    const item = {
      ...this.product,
      quantity: undefined,
      modificationId: modification ? modification.id : null
    }

    this.doc.insertLineItem(item)
  }

  destroy = async (e) => {
    e && e.preventDefault()

    if (this.doc.isReadonly()) return
    if (!this.id || !this.productId) return

    this.retry = 'destroy'
    this.toggleErrorIcon(false)
    this.toggleProgressIcon(true)
    this.doc.onDestroyLineItem(this.id)
    const params = formToObject(this.doc.form, 'document')
    // Using qs.stringify to encode strings, because it could contain % or other
    // special url character. This is not necessary for POST and PUT, where params goes in body.
    const url = `${this.resourceUrl()}?${qs.stringify(params)}`

    try {
      const response = await axios.delete(url)
      const data = response.data
      this.toggleProgressIcon(false)
      if (data.error) return this.handleRequestError(data.error)
      this.el.remove()
      this.retry = null
    } catch (error) {
      this.doc.rollbackLineItems()
      this.handleRequestError(errorMessage(error))
      console.error(e)
    }
  }

  handleRequestError(message) {
    this.toggleProgressIcon(false)
    this.toggleErrorIcon(true, message)
  }

  onEdit = (e) => {
    e.preventDefault()
    this.editMode = true
    this.search.onEnterEditMode()
    this.el.addClass('edit-mode')
  }

  onPrint = (e) => {
    e.preventDefault()
    const masterTemplateId = $(e.currentTarget).data('master-template-id') || ''
    window.open(`${gon.locale_path}/platform/document_items/${this.id}.pdf?format=pdf&master_template_id=${masterTemplateId}`, '_blank')
  }

  openProductPage = () => {
    window.open(`${gon.locale_path}/platform/products/${this.productId}`, '_blank')
  }

  openProductCalculation = () => {
    window.location = `${gon.locale_path}/platform/manufacture/material_items?document_item_id=${this.id}`
  }

  cancelEdit() {
    this.search.onCancelEditMode()
    this.el.removeClass('edit-mode')
    this.editMode = false
  }

  openLineItemMovements = async (e) => {
    if (e.target !== e.currentTarget || !this.id) return

    const $modal = $('.line-item-movements-modal')
    const response = await axios.get(`${gon.locale_path}/platform/document_items/${this.id}/movements.json`)

    $modal.find('.table-container').html(response.data.table)
    $modal.modal('show')
  }

  openProductPage = (e) => {
    e.preventDefault()
    const productPath = `${gon.locale_path}/platform/products/${this.productId}`
    window.open(productPath, '_blank')
  }

  openProductModal = (e) => {
    e.preventDefault()

    const newProductAttributes = {
      product_type: this.doc.isManufacture() ? 'manufacture' : 'goods'
    }

    this.doc.productModal.open(this.productId, {
      onCreate: this.setLineItem,
      onUpdate: this.onProductUpdate,
      onReplace: this.setLineItem,
      onError: (e) => this.handleRequestError(errorMessage(e)),
      newProductAttributes
    })
  }

  openProductsCatalog = (e) => {
    e.preventDefault()

    this.doc.$productsCatalog.show()
  }

  navigate = (e) => {
    if (e.which !== 13) return
    e.preventDefault()
    const $field = $(e.target)
    if ($field.hasClass('search-field') && (this.editMode || this.el.hasClass('empty'))) return this.search.handleSearchEnter()
    this.navigateToNextField($field)
  }

  navigateToNextField($field) {
    const $lineItem = $field.closest('.line-item')
    if (!$lineItem.length) return

    const $input = this.findNextFocusInput($field)

    if (!$input || !$input.length) return

    if ($input.data('widget') === 'Select') {
      $input.select2('focus')
    } else {
      $input.focus()
    }
  }

  findNextFocusInput($field) {
    let $cell = $field.closest('.table-cell')
    let $found
    let i = 0

    while ($cell.length && !$found && i < 30) {
      i += 1 // just to ensure no endless loop and no sense to search more than 30 times
      const $current = $cell
      $cell = $cell.next()

      if (!$cell.length) $cell = $current.closest('.table-row').next().find('.table-cell').first()

      // Skip all selects and buttons, seems like better to focus on inputs
      const $input = $cell.find('input:not([type=hidden]):not(:disabled):not([readonly])')

      if ($input.length) $found = $input
    }

    return $found
  }

  isManualDiscountDisabled() {
    return !this.doc.companySettings.allow_manual_discount || !!this.product.discount_disabled
  }

  handleInputFocus = (e) => {
    $(e.target).closest('tr, .table-row').addClass('focused')
  }

  handleInputUnfocus = (e) => {
    $(e.target).closest('tr, .table-row').removeClass('focused')
  }

  toggleProgressIcon(show) {
    this.el.find('.status-icon.progress-icon').toggle(show)
  }

  toggleErrorIcon(show, message) {
    this.el.toggleClass('has-error', show)
    const icon = this.el.find('.status-icon.error-icon').toggle(show)
    if (show) {
      icon.tooltip({ placement: 'right' })
      icon.data('bs.tooltip').options.title = message
      icon.tooltip('show')
      delay(() => icon.tooltip('hide'), 5000)
    }
  }

  isProductKit() {
    return this.product && this.product.product_type === 'kit'
  }

  useTwoMeasures() {
    return this.product && this.product.use_two_measures
  }

  setMetadata(data) {
    this.$metadata.val(JSON.stringify(decamelizeKeys(data)))
  }

  fetchMetadata() {
    return camelizeKeys(JSON.parse(this.$metadata.val() || '{}'))
  }

  fetchModificationId() {
    return this.modifications.selectedId()
  }

  // Triggered from product card modal only on update (for create we use setLineItem)
  onProductUpdate = (item) => {
    if (this.doc.isReadonly()) return

    this.blockCalculate = true
    this.product = item
    this.search.onSetLineItem(item)
    this.setImage()

    this.blockCalculate = false
  }

  // FIXME: Measure set is wrong, because you should set measure_id.
  // Fix it, and then remove setting measure in document_item.rb model from product measure.
  // And also, measure_rate should be implemented
  setLineItem = (item) => {
    if (this.doc.isReadonly()) return

    this.blockCalculate = true
    this.product = item
    this.productId = item.id
    // Fetch quantity should be before onSetLineItem,
    // because we need extract weight from barcode for some products
    this.setQuantityFields()
    this.search.onSetLineItem(item)
    this.el.find('.product-id').val(this.productId)
    this.el.find('.ord-position').val(this.position)
    this.el.find('.amount-expenses').val(this.product.amount_expenses)
    this.el.find('.nds-rate').val(this.product.nds_rate)
    this.el.find('.markup-retail').val((this.product.metadata || {}).markup_retail)
    this.el.find('.markup-wholesale').val((this.product.metadata || {}).markup_wholesale)
    this.calculator.$discountField.prop('disabled', this.isManualDiscountDisabled())
    this.setMetadata({})

    this.el.removeClass('empty')

    this.serials.resetSerials()
    this.setProductAccountingFields()
    this.setManufactureFields()
    this.setRemains()
    this.setImage()

    this.blockCalculate = false

    this.calculate({recalculatePrices: true, recalculateDiscounts: true, recalculateCurrency: true})
    this.search.clearSearchResults()
    this.doc.addEmptyLineItem()
  }

  setRemains() {
    const modification = this.modifications.selected()
    this.remains = Number((modification || this.product).remains || 0)
    this.reserve = Number((modification || this.product).reserve || 0)
    this.available = Math.max(subtract(this.remains, this.reserve), 0)
    this.el.find('.remains-box .remain').html(formattedQuantity(this.remains, { zero: '-', pretty: true }))
    this.el.find('.remains-box .reserve .value').text(this.reserve)
    this.el.find('.remains-box .reserve').toggle(this.reserve > 0)

    this.validateQuantity()
  }

  setImage() {
    const modification = this.modifications.selected()
    const micro = ((modification || {}).images || {}).micro || ((this.product || {}).images || {}).micro
    const large = ((modification || {}).images || {}).large || ((this.product || {}).images || {}).large

    this.el.find('.action-field').toggleClass('with-image', !!micro)
    this.el.find('.image-item').data('image-large', large)
    this.el.find('.image-item img').attr('src', micro)
  }

  setQuantityFields() {
    const quantity = this.justInsertedItemQuantity()
    const $quantityBox = this.el.find('.quantity-box')
    const useTwoMeasures = this.useTwoMeasures()

    this.el.find('.quantity').val(quantity)
    this.el.find('.quantity-measure-base').val(quantity)
    this.el.find('.quantity-measure-second').val(0)
    this.el.find('.measure-id').text(this.product.measure_id)
    this.el.find('.measure-rate').val(this.product.measure_rate || 1)

    $quantityBox.find('.measure-base').text(this.product.measure)
    $quantityBox.find('.measure-second').text(this.product.measure_second)
    $quantityBox.toggleClass('with-measures', useTwoMeasures)
    $quantityBox.toggleClass('with-serials', !useTwoMeasures)
  }

  validateQuantity() {
    if (this.doc.data.closed || !['client_order', 'sale', 'retail'].includes(this.doc.typedoc)) return

    const quantity = fetchNumberInputValue(this.el.find('.quantity'))
    const hasError = this.available < quantity

    if (this.useTwoMeasures()) {
      this.el.find('.quantity-measure-base').toggleClass('with-error', hasError)
    } else {
      this.el.find('.quantity').toggleClass('with-error', hasError)
    }
  }

  justInsertedItemQuantity() {
    if (this.isSerial() && !this.doc.data.is_order && this.doc.typedoc !== 'payment_invoice') return 0
    if (this.product.quantity || this.product.quantity === 0) return this.product.quantity

    const query = this.search.$search.val()

    if (isWeightBarcode(query, this.doc.companySettings.weight_barcode_prefix, { checkIsFull: true })) {
      return extractWeightFromBarcode(query)
    }

    return 1
  }

  isSerial() {
    return !!this.product.serial_accounting
  }

  prices() {
    const selectedModification = this.modifications.selected()

    if (selectedModification && selectedModification.use_prices) {
      return selectedModification.prices
    }

    return this.product.prices
  }

  setManufactureFields() {
    if (isLineItemAllowMaterials(this.product.product_type, this.doc.typedoc)) {
      this.el.find('.action-field').addClass('with-calculation-btn')
    } else {
      this.el.find('.action-field').removeClass('with-calculation-btn')
    }
  }

  setProductAccountingFields() {
    this.modifications.reload()
    this.datevalids.reload()

    // setPartySelect will be called on each modification change,
    // so we don't need to call it twice.
    if (this.doc.isSelectPartyMode() && !this.modifications.isEnabled() && !this.datevalids.isEnabled()) {
      this.parties.setPartySelect()
    }

    if (this.isSerial() && !this.doc.data.is_order && !this.doc.applyProductsFromCatalogPending) {
      this.serials.openSerialsModal()
    }
  }
}

export default LineItem
